import React, { FC } from 'react'

import Layout from '../components/common/Layout'
import SEO from '../components/common/SEO'

const OrderErrorPage: FC = () => (
    <Layout>
        <SEO title='Order - Error' />
        <h1>Ein Fehler ist aufgetreten, versuchen Sie es später erneut.</h1>
    </Layout>
)

export default OrderErrorPage
